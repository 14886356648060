@import "../base/var";

.get-metal {
  background: $table-stroke;
  position: relative;
  width: 100%;
  overflow: hidden;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 40px;
    background: $white;
    display: block;
    top: 0;
    left: 0;
    z-index: 0;

    @media (max-width: $xs) {
      display: none;
    }
  }
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 40px;
    background: $white;
    display: block;
    bottom: 0;
    left: 0;
    z-index: 0;

    @media (max-width: $xs) {
      display: none;
    }
  }

  &__content {
    display: flex;
    align-items: center;

    @media (max-width: $xs) {
      display: block;
    }
  }

  &__text {
    width: 60%;
    font-size: 18px;
    padding: 60px 0;
    margin-right: 75px;

    p {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (max-width: $md) {
      font-size: 16px;
    }

    @media (max-width: $sm) {
      margin-right: 30px;
    }

    @media (max-width: $xs) {
      width: 100%;
      padding: 30px 0;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: $b;
    margin: 0 0 40px;
    position: relative;
    max-width: 70%;

    &:after {
      content: '';
      position: absolute;
      width: calc((100vw - 1550px)/2 + 240px);
      height: 4px;
      left: calc(0px - ((100vw - 1550px)/2) - 30px);
      bottom: -20px;
      background: linear-gradient(98.36deg, $yellow 24.88%, $orange 106.19%);

      @media (max-width: $wrap) {
        width: 270px;
        left: -30px;
      }
    }

    @media (max-width: 1350px) {
      max-width: 100%;
    }

    @media (max-width: $md) {
      font-size: 24px;
    }
  }

  &__skew-block {
    position: absolute;
    height: 100%;
    width: calc((100vw - 1550px) / 2 + 100px);
    background: $white;
    top: 0;
    right: -100px;
    transform: skewX(20deg);

    @media (max-width: 1350px) {
      display: none;
    }
  }
}