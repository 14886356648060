@import "../base/var";

.header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    background: $white;
    box-shadow: 0px 2px 10px rgba(35, 35, 35, 0.1);

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        @media (max-width: $xs) {
            flex-direction: column-reverse;
            align-items: flex-end;
        }
    }

    &__side {
        width: 250px;
        flex-shrink: 0;

        &_big {
            width: auto;
            flex-shrink: 1;

            @media (max-width: $xs) {
                align-self: flex-start;
            }
        }

        &:first-child {
            @media (max-width: $md) {
                display: none;
            }
        }

        @media (max-width: $sm) {
            width: auto;
        }
    }

    &__phone {
        color: $black;
        font-size: 24px;
        line-height: normal;
        padding-left: 45px;
        position: relative;
        margin: 20px 0;
        display: block;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 2px;
            width: 24px;
            height: 24px;
            display: block;
            background: url('../img/header_phone.svg');

            @media (max-width: $sm) {
                top: -1px;
            }
        }

        @media (max-width: $sm) {
            font-size: 18px;
            padding-left: 35px;
        }

        @media (max-width: $xs) {
            margin: 15px 0;
        }
    }
}

.menu {
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: center;

        @media (max-width: $xs) {
            display: block;
        }
    }

    &__item {
        margin: 20px 17px;

        @media (max-width: $sm) {
            margin: 20px 15px;
        }

        @media (max-width: $xs) {
            margin: 20px 0;
        }
    }

    &__link {
        font-size: 16px;
        line-height: normal;
        color: $black;

        @media (max-width: $sm) {
            font-size: 14px;
        }
    }

    &__mobile {
        position: absolute;
        top: 10px;
        left: 0;
        float: none;
        margin: 0;
    }

    &__mobile-line {
        background: $black;
    }
}