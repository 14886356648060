/* colors */
/* media */
/* colors */
/* media */
body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: normal;
  overflow-x: hidden;
}

p {
  margin: 0;
}

button, input, textarea, select {
  outline: none;
}

.wrapper {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
}

@media (max-width: 1550px) {
  .wrapper {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    padding: 0 15px;
  }
}

a {
  transition: 0.3s;
}

a:hover {
  color: #F37500;
}

.round-icon {
  box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 162px;
}

.custom-checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 0;
}

.custom-checkbox__text {
  position: relative;
  padding: 0 0 0 25px;
  cursor: pointer;
  font-weight: normal;
  font-size: 16px;
}

.custom-checkbox__text:before {
  content: '';
  position: absolute;
  display: block;
  top: 1px;
  left: 0;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 4px solid #AAA5A5;
}

.custom-checkbox input:checked + .custom-checkbox__text:before {
  border-color: #F7CD47;
}

/* colors */
/* media */
.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  background: #fff;
  box-shadow: 0px 2px 10px rgba(35, 35, 35, 0.1);
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

@media (max-width: 767px) {
  .header__content {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}

.header__side {
  width: 250px;
  flex-shrink: 0;
}

.header__side_big {
  width: auto;
  flex-shrink: 1;
}

@media (max-width: 767px) {
  .header__side_big {
    align-self: flex-start;
  }
}

@media (max-width: 1199px) {
  .header__side:first-child {
    display: none;
  }
}

@media (max-width: 991px) {
  .header__side {
    width: auto;
  }
}

.header__phone {
  color: #000;
  font-size: 24px;
  line-height: normal;
  padding-left: 45px;
  position: relative;
  margin: 20px 0;
  display: block;
}

.header__phone:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 24px;
  height: 24px;
  display: block;
  background: url("../img/header_phone.svg");
}

@media (max-width: 991px) {
  .header__phone:before {
    top: -1px;
  }
}

@media (max-width: 991px) {
  .header__phone {
    font-size: 18px;
    padding-left: 35px;
  }
}

@media (max-width: 767px) {
  .header__phone {
    margin: 15px 0;
  }
}

.menu__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .menu__list {
    display: block;
  }
}

.menu__item {
  margin: 20px 17px;
}

@media (max-width: 991px) {
  .menu__item {
    margin: 20px 15px;
  }
}

@media (max-width: 767px) {
  .menu__item {
    margin: 20px 0;
  }
}

.menu__link {
  font-size: 16px;
  line-height: normal;
  color: #000;
}

@media (max-width: 991px) {
  .menu__link {
    font-size: 14px;
  }
}

.menu__mobile {
  position: absolute;
  top: 10px;
  left: 0;
  float: none;
  margin: 0;
}

.menu__mobile-line {
  background: #000;
}

/* colors */
/* media */
.banner {
  position: relative;
  width: 100%;
  margin-top: 69px;
}

.banner:before {
  content: "";
  display: block;
  padding-top: 36.7%;
}

@media (max-width: 767px) {
  .banner:before {
    display: none;
  }
}

@media (max-width: 767px) {
  .banner {
    margin-top: 52px;
  }
}

.banner__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.banner__image:after {
  position: absolute;
  content: '';
  display: block;
  width: 60%;
  height: 100%;
  top: 0;
  left: -100px;
  transform: skewX(20deg);
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

@media (max-width: 1350px) {
  .banner__image:after {
    width: 70%;
  }
}

@media (max-width: 1199px) {
  .banner__image:after {
    width: 72%;
  }
}

@media (max-width: 991px) {
  .banner__image:after {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .banner__image:after {
    transform: none;
    left: 0;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .banner__image {
    position: relative;
  }
}

.banner__flex {
  height: 100%;
  display: flex;
}

.banner__content {
  z-index: 2;
  position: relative;
  max-width: 625px;
  margin: auto 0;
}

@media (max-width: 1199px) {
  .banner__content {
    max-width: 525px;
  }
}

@media (max-width: 767px) {
  .banner__content {
    max-width: 100%;
    margin: 45px 0 50px;
  }
}

.banner__title {
  font-size: 36px;
  color: #fff;
}

@media (max-width: 1199px) {
  .banner__title {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .banner__title {
    font-size: 24px;
  }
}

.banner__text {
  font-size: 18px;
  color: #fff;
  margin-top: 20px;
}

@media (max-width: 1199px) {
  .banner__text {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .banner__text {
    font-size: 14px;
    margin-top: 15px;
  }
}

.banner__button-wrap {
  margin-top: 65px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .banner__button-wrap {
    margin-top: 45px;
  }
}

.banner__button {
  border-radius: 4px;
  background: linear-gradient(296.18deg, #FF0119 -2.13%, #F7CD47 120.44%);
  color: #fff;
  font-size: 18px;
  padding: 11px 35px;
  border: 0;
  transition: 0.3s;
}

.banner__button:hover {
  background: linear-gradient(296.18deg, #F7CD47 -2.13%, #FF0119 120.44%);
}

@media (max-width: 767px) {
  .banner__button {
    font-size: 14px;
  }
}

/* colors */
/* media */
.advantages {
  margin: 45px 0;
}

@media (max-width: 767px) {
  .advantages {
    margin: 20px 0;
  }
}

.advantages__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.advantages__item {
  width: 31%;
  display: flex;
  margin: 15px 0;
}

@media (max-width: 991px) {
  .advantages__item {
    width: 48%;
  }
}

@media (max-width: 767px) {
  .advantages__item {
    width: 100%;
  }
}

.advantages__icon {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}

.advantages__text {
  margin-left: 15px;
}

.advantages__title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.advantages__description {
  font-size: 16px;
}

@media (max-width: 767px) {
  .advantages__description {
    font-size: 14px;
  }
}

/* colors */
/* media */
.get-metal {
  background: #f8f8f8;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.get-metal:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 40px;
  background: #fff;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
}

@media (max-width: 767px) {
  .get-metal:before {
    display: none;
  }
}

.get-metal:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 40px;
  background: #fff;
  display: block;
  bottom: 0;
  left: 0;
  z-index: 0;
}

@media (max-width: 767px) {
  .get-metal:after {
    display: none;
  }
}

.get-metal__content {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .get-metal__content {
    display: block;
  }
}

.get-metal__text {
  width: 60%;
  font-size: 18px;
  padding: 60px 0;
  margin-right: 75px;
}

.get-metal__text p {
  margin-bottom: 25px;
}

.get-metal__text p:last-child {
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .get-metal__text {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .get-metal__text {
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .get-metal__text {
    width: 100%;
    padding: 30px 0;
  }
}

.get-metal__title {
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 40px;
  position: relative;
  max-width: 70%;
}

.get-metal__title:after {
  content: '';
  position: absolute;
  width: calc((100vw - 1550px)/2 + 240px);
  height: 4px;
  left: calc(0px - ((100vw - 1550px)/2) - 30px);
  bottom: -20px;
  background: linear-gradient(98.36deg, #F7CD47 24.88%, #F37500 106.19%);
}

@media (max-width: 1550px) {
  .get-metal__title:after {
    width: 270px;
    left: -30px;
  }
}

@media (max-width: 1350px) {
  .get-metal__title {
    max-width: 100%;
  }
}

@media (max-width: 1199px) {
  .get-metal__title {
    font-size: 24px;
  }
}

.get-metal__skew-block {
  position: absolute;
  height: 100%;
  width: calc((100vw - 1550px) / 2 + 100px);
  background: #fff;
  top: 0;
  right: -100px;
  transform: skewX(20deg);
}

@media (max-width: 1350px) {
  .get-metal__skew-block {
    display: none;
  }
}

/* colors */
/* media */
.metal-form {
  color: #fff;
  padding: 32px 24px;
  background: linear-gradient(143.47deg, #F7CD47 -0.98%, #F37500 145.11%);
  width: 440px;
  height: 100%;
  flex-shrink: 0;
  border-radius: 4px;
  z-index: 1;
}

@media (max-width: 991px) {
  .metal-form {
    width: 300px;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .metal-form {
    padding: 15px;
    margin: auto;
  }
}

.metal-form__title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .metal-form__title {
    margin-bottom: 15px;
  }
}

.metal-form__label {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
  display: block;
}

@media (max-width: 767px) {
  .metal-form__label {
    font-size: 14px;
  }
}

.metal-form__number-wrap {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 100%;
  border: 0;
  border-radius: 4px;
  padding: 10px 45px 10px 15px;
  position: relative;
}

.metal-form__number {
  color: #000;
  width: 100%;
  font-size: 18px;
  border: 0;
}

.metal-form__number-more {
  position: absolute;
  top: 5px;
  right: 15px;
  width: 0;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #AAA5A5 transparent;
  cursor: pointer;
}

.metal-form__number-less {
  position: absolute;
  bottom: 5px;
  right: 15px;
  width: 0;
  border-width: 7px;
  border-style: solid;
  border-color: #AAA5A5 transparent transparent transparent;
  cursor: pointer;
}

.metal-form__checkboxes {
  margin-top: 15px;
  margin-bottom: 20px;
}

.metal-form__checkbox {
  margin-right: 32px;
}

.metal-form__phone {
  border: 0;
  background: transparent;
  font-size: 24px;
  font-weight: normal;
}

.metal-form__phone::placeholder {
  color: #fff;
}

.metal-form__phone::-webkit-input-placeholder {
  color: #fff;
}

.metal-form__phone::-moz-placeholder {
  color: #fff;
}

.metal-form__button {
  display: block;
  width: 100%;
  height: 45px;
  background: #fff;
  border-radius: 4px;
  font-size: 18px;
  color: #000;
  border: 0;
  margin: 30px 0 0;
  transition: 0.3s;
}

.metal-form__button:hover {
  background: linear-gradient(296.18deg, #FF0119 -2.13%, #F7CD47 120.44%);
  color: #fff;
}

.select2-container {
  margin-bottom: 15px;
  background: #fff;
  border-radius: 4px;
  height: 44px;
}

.select2-container .select2-selection--single {
  outline: none;
  border: 0;
  font-size: 18px;
  padding: 8px 0 0;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0 15px;
}

.select2-container .select2-selection--single .select2-selection__arrow {
  top: 8px;
  right: 15px;
}

.select2-container .select2-selection--single .select2-selection__arrow b {
  border-width: 7px;
  border-style: solid;
  border-color: #AAA5A5 transparent transparent transparent;
}

.select2-container .select2-dropdown {
  border: 0;
}

.select2-container .select2-dropdown .select2-search {
  display: none;
}

.select2-container .select2-dropdown .select2-results__option {
  padding: 7px 15px;
  cursor: pointer;
}

.select2-container .select2-dropdown .select2-results__option--highlighted {
  background-color: #F7CD47;
}

/* colors */
/* media */
.our-numbers {
  padding: 60px 0 50px;
}

@media (max-width: 767px) {
  .our-numbers {
    padding: 30px 0;
  }
}

.our-numbers__title {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.our-numbers__content {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

@media (max-width: 1199px) {
  .our-numbers__content {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .our-numbers__content {
    margin-top: 0;
  }
}

.our-numbers__item {
  margin-top: 20px;
  flex-grow: 1;
  max-width: 20%;
}

@media (max-width: 1199px) {
  .our-numbers__item {
    max-width: 33%;
  }
}

@media (max-width: 767px) {
  .our-numbers__item {
    width: 100%;
    max-width: 100%;
  }
}

.our-numbers__icon {
  margin: auto;
}

.our-numbers__text {
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  max-width: 220px;
  margin: 25px auto 0;
}

@media (max-width: 1199px) {
  .our-numbers__text {
    max-width: 260px;
  }
}

/* colors */
/* media */
.price {
  margin-top: 50px;
}

@media (max-width: 767px) {
  .price {
    margin-top: 30px;
  }
}

.price__main-title {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .price__main-title {
    margin-top: 0;
  }
}

.price__wrap {
  max-width: 900px;
  margin: 0 auto;
}

.price__wrap_flex {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .price__wrap {
    max-width: 100%;
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .price__wrap {
    padding: 0 15px;
  }
}

.price__text {
  font-size: 18px;
  font-weight: 500;
  text-align: justify;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .price__text {
    font-size: 14px;
  }
}

.price__text_small {
  font-size: 16px;
  font-weight: 400;
  margin-top: 25px;
}

@media (max-width: 767px) {
  .price__text_small {
    font-size: 12px;
  }
}

.price__tabs {
  display: flex;
  justify-content: space-between;
  border: 0;
  margin-top: 25px;
  margin-bottom: 35px;
}

@media (max-width: 767px) {
  .price__tabs {
    flex-wrap: wrap;
    margin: 10px 0 30px;
  }
}

.price__tab {
  flex-grow: 1;
}

@media (max-width: 767px) {
  .price__tab {
    width: 100%;
  }
}

.price__tab.active {
  background: linear-gradient(108.15deg, #F7CD47 7.65%, #F37500 126.54%);
  border: 0;
  border-radius: 4px;
}

.price__tab.active .price__link {
  font-size: 18px;
  font-weight: 700;
  color: #fff !important;
}

@media (max-width: 991px) {
  .price__tab.active .price__link {
    font-size: 14px;
  }
}

.price__link {
  display: block;
  width: 100%;
  text-align: center;
  background: transparent !important;
  border: 0 !important;
  font-weight: normal;
  font-size: 16px;
  color: #000 !important;
}

.price__link:hover {
  color: #F37500 !important;
}

@media (max-width: 991px) {
  .price__link {
    font-size: 14px;
  }
}

.price__blocks .tab-pane {
  min-height: 400px;
}

@media (max-width: 767px) {
  .price__blocks .tab-pane {
    min-height: 0;
  }
}

.price__table {
  font-size: 16px;
  font-weight: normal;
}

@media (max-width: 991px) {
  .price__table {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .price__table {
    font-size: 10px;
  }
}

.price__row_head {
  background: #ececec !important;
}

.price__row:nth-child(odd) {
  background: #f8f8f8;
}

.price__cell {
  width: 25%;
  padding: 10px 0;
}

@media (max-width: 767px) {
  .price__cell {
    text-align: center;
  }
}

/* colors */
/* media */
.why {
  padding: 60px 0 0;
}

@media (max-width: 767px) {
  .why {
    padding-top: 30px;
  }
}

.why__main-title {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 60px;
}

@media (max-width: 767px) {
  .why__main-title {
    margin-bottom: 30px;
  }
}

.why__item {
  align-items: center;
  width: 33%;
}

@media (max-width: 1199px) {
  .why__item {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .why__item {
    width: 100%;
  }
}

.why__icon {
  flex-shrink: 0;
}

@media (max-width: 1350px) {
  .why__icon {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 1350px) {
  .why__icon img {
    max-width: 55%;
  }
}

.why__text {
  margin-left: 30px;
}

@media (max-width: 991px) {
  .why__text {
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .why__text {
    padding-right: 0;
  }
}

.why__title {
  font-size: 23px;
  margin-bottom: 15px;
}

@media (max-width: 1550px) {
  .why__title {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .why__title {
    font-size: 16px;
  }
}

/* colors */
/* media */
.three {
  background: rgba(229, 229, 229, 0.2);
  padding: 40px 0;
  margin: 70px 0;
}

@media (max-width: 1199px) {
  .three {
    padding: 30px 0;
  }
}

@media (max-width: 991px) {
  .three {
    padding: 20px 0;
  }
}

@media (max-width: 767px) {
  .three {
    padding: 15px 0;
    margin: 15px 0;
  }
}

.three__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .three__content {
    flex-wrap: wrap;
  }
}

.three__item {
  width: 30%;
  display: flex;
  align-items: center;
}

@media (max-width: 1199px) {
  .three__item {
    width: 32%;
  }
}

@media (max-width: 767px) {
  .three__item {
    width: 100%;
    margin: 15px 0;
  }
}

.three__icon {
  flex-shrink: 0;
}

@media (max-width: 1199px) {
  .three__icon {
    width: 50px;
  }
}

@media (max-width: 991px) {
  .three__icon {
    width: 30px;
  }
}

@media (max-width: 767px) {
  .three__icon {
    width: 60px;
  }
}

.three__text {
  font-weight: normal;
  font-size: 16px;
  margin-left: 35px;
}

@media (max-width: 1199px) {
  .three__text {
    font-size: 14px;
    margin-left: 15px;
  }
}

@media (max-width: 991px) {
  .three__text {
    font-size: 12px;
  }
}

/* colors */
/* media */
.contacts {
  margin: 0 auto;
  width: 1290px;
  max-width: 100%;
  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.1);
  background: #fff;
  display: flex;
  z-index: 1;
}

@media (max-width: 1350px) {
  .contacts {
    margin: 0 30px;
    max-width: calc(100% - 60px);
  }
}

@media (max-width: 991px) {
  .contacts {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

@media (max-width: 767px) {
  .contacts {
    margin: 0 15px;
    max-width: calc(100% - 30px);
  }
}

.contacts__map {
  width: 55%;
  flex-shrink: 0;
}

@media (max-width: 1199px) {
  .contacts__map {
    width: 45%;
  }
}

@media (max-width: 991px) {
  .contacts__map {
    width: 100%;
    height: 500px;
  }
}

@media (max-width: 767px) {
  .contacts__map {
    height: 300px;
  }
}

.contacts__content {
  padding: 25px 0 30px;
  flex-grow: 1;
  background: #fff;
}

.contacts__title {
  font-weight: 700;
  font-size: 24px;
  color: #000;
  margin: 0 28px 25px;
}

@media (max-width: 767px) {
  .contacts__title {
    margin: 0 15px 20px;
  }
}

.contacts__address {
  font-size: 14px;
  font-weight: 400;
  min-height: 36px;
  margin: 0 28px;
  position: relative;
  padding: 8px 0 0 60px;
}

@media (max-width: 767px) {
  .contacts__address {
    margin: 0 15px;
  }
}

.contacts__address:before {
  content: '';
  position: absolute;
  background: #fff;
  box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.1), inset 0px 4px 4px rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  display: block;
  border-radius: 50%;
  left: 0;
  top: calc(50% - 18px);
}

.contacts__address:after {
  content: '';
  position: absolute;
  background: url("../img/address.svg");
  background-size: contain;
  width: 14px;
  height: 18px;
  display: block;
  left: 11px;
  top: calc(50% - 9px);
}

.contacts__credits {
  display: flex;
  background: rgba(229, 229, 229, 0.2);
  margin-top: 15px;
  padding: 16px 28px;
  width: 100%;
}

@media (max-width: 767px) {
  .contacts__credits {
    display: block;
    padding: 10px 15px;
  }
}

.contacts__link {
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  padding-left: 46px;
  padding-top: 7px;
  display: block;
  min-height: 30px;
}

.contacts__link:before {
  content: '';
  position: absolute;
  background: #fff;
  box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.1), inset 0px 4px 4px rgba(0, 0, 0, 0.1);
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
  left: 0;
  top: 0;
}

.contacts__link:after {
  content: '';
  position: absolute;
  background-size: contain;
  display: block;
  width: 12px;
  height: 12px;
  top: 9px;
  left: 9px;
}

.contacts__link_phone:after {
  background-image: url("../img/contacts_phone.svg");
}

.contacts__link_email {
  margin-left: 32px;
}

.contacts__link_email:after {
  background-image: url("../img/email.svg");
}

@media (max-width: 767px) {
  .contacts__link_email {
    margin-left: 0;
    margin-top: 10px;
  }
}

.contacts__text {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  margin: 25px 85px 0 28px;
}

@media (max-width: 767px) {
  .contacts__text {
    margin: 15px 15px;
  }
}

.contacts__form {
  margin: 10px 47px 0 28px;
}

@media (max-width: 767px) {
  .contacts__form {
    margin: 10px 15px 0;
  }
}

.contacts__inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .contacts__inputs {
    flex-wrap: wrap;
  }
}

.contacts__input-wrap {
  border-bottom: 1px solid #AAA5A5;
  padding: 10px 0 10px 32px;
  position: relative;
  width: 45%;
}

@media (max-width: 767px) {
  .contacts__input-wrap {
    width: 100%;
  }
}

.contacts__input-wrap:before {
  display: block;
  width: 18px;
  height: 18px;
  content: '';
  top: 10px;
  left: 0;
  position: absolute;
}

.contacts__input-wrap_phone:before {
  background: url("../img/contacts_input_phone.svg");
}

.contacts__input-wrap_email:before {
  background: url("../img/contacts_input_email.svg");
}

.contacts__input {
  width: 100%;
  font-size: 14px;
  color: #000;
  border: 0;
}

.contacts__checkbox-text {
  font-size: 10px;
  color: #878787;
  padding-left: 18px;
}

.contacts__checkbox-text:before {
  top: 2px;
  width: 9px;
  height: 9px;
  border-width: 2px;
}

.contacts__button {
  margin: 20px auto 0;
  display: block;
  width: 316px;
  max-width: 100%;
  height: 45px;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 400;
  background: linear-gradient(305.23deg, #FF0119 -2.13%, #F7CD47 120.44%);
  transition: 0.3s;
}

.contacts__button:hover {
  background: linear-gradient(305.23deg, #F7CD47 -2.13%, #FF0119 120.44%);
}

@media (max-width: 767px) {
  .contacts__button {
    width: 100%;
  }
}

/* colors */
/* media */
.footer {
  background: rgba(229, 229, 229, 0.2);
  margin: -50px 0 0;
  z-index: 0;
  padding: 113px 0 0;
}

@media (max-width: 767px) {
  .footer {
    padding-top: 75px;
  }
}

.footer__content {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .footer__content {
    display: block;
  }
}

.footer__address {
  margin: 0;
}

@media (max-width: 991px) {
  .footer__address {
    font-size: 12px;
    padding-left: 50px;
  }
}

.footer__credits {
  background: none;
  padding: 0;
}

@media (max-width: 991px) {
  .footer__credits {
    display: block;
  }
}

@media (max-width: 991px) {
  .footer__email {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .footer__right {
    margin-top: 30px;
  }
}

.footer__menu-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__title {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.footer__social {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: linear-gradient(180deg, #F7CD47 0%, #F37500 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__social img {
  max-width: 16px;
}

.footer__menu {
  margin: 15px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .footer__menu {
    display: block;
  }
}

.footer__item {
  margin-left: 50px;
}

@media (max-width: 991px) {
  .footer__item {
    margin-left: 15px;
  }
}

@media (max-width: 767px) {
  .footer__item {
    margin-left: 0;
    margin-top: 15px;
  }
}

.footer__item:first-child {
  margin-left: 0;
  margin-top: 0;
}

.footer__link {
  font-size: 14px;
  color: #000;
  display: block;
}

.footer__link_grey {
  color: #878787;
}

.footer__link_small {
  font-size: 10px;
}

.footer__link_margin {
  margin: 0 10px;
}

.footer__under {
  background: #fff;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

@media (max-width: 767px) {
  .footer__under {
    margin-top: 30px;
  }
}

/* colors */
/* media */
#agreement-modal {
  background: rgba(228, 228, 229, 0.75);
}

#agreement-modal .modal-dialog {
  width: 930px;
  max-width: 930px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  pointer-events: all;
}

@media (max-width: 991px) {
  #agreement-modal .modal-dialog {
    width: 95%;
  }
}

#agreement-modal .modal-dialog .buy_form_wrap {
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.3);
  width: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  padding: 40px 10px 10px 10px;
}

@media (max-width: 767px) {
  #agreement-modal .modal-dialog .buy_form_wrap {
    padding-top: 30px;
  }
}

#agreement-modal .modal-dialog .closee {
  font-size: 25px;
  position: absolute;
  right: 12px;
  top: -3px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  display: block;
}

#agreement-modal.in .modal-dialog {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#agreement-modal .for-scroll {
  height: 500px;
  padding: 0 25px 25px;
  overflow: auto;
}

@media (max-width: 767px) {
  #agreement-modal .for-scroll {
    padding: 0 15px;
  }
}

#agreement-modal .for-scroll h6 {
  text-align: center;
  font-weight: 700;
  font-family: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 26px;
}

@media (max-width: 767px) {
  #agreement-modal .for-scroll h6 {
    font-size: 18px;
  }
}

#agreement-modal .for-scroll p {
  font-size: 14px;
  margin-bottom: 15px;
}

#agreement-modal .for-scroll::-webkit-scrollbar {
  background: #fff;
  width: 8px;
}

#agreement-modal .for-scroll::-webkit-scrollbar-thumb {
  background: #F37500;
  border-radius: 4px;
  cursor: pointer;
}
