@import "../base/var";

.why {
  padding: 60px 0 0;

  @media (max-width: $xs) {
    padding-top: 30px;
  }

  &__main-title {
    text-align: center;
    font-size: 24px;
    font-weight: $b;
    margin: 0 0 60px;

    @media (max-width: $xs) {
      margin-bottom: 30px;
    }
  }

  &__item {
    align-items: center;
    width: 33%;

    @media (max-width: $md) {
      width: 50%;
    }

    @media (max-width: $xs) {
      width: 100%;
    }
  }

  &__icon {
    flex-shrink: 0;

    @media (max-width: 1350px) {
      width: 100px;
      height: 100px;
    }

    img {
      @media (max-width: 1350px) {
        max-width: 55%;
      }
    }
  }

  &__text {
    margin-left: 30px;

    @media (max-width: $sm) {
      padding-right: 30px;
    }

    @media (max-width: $xs) {
      padding-right: 0;
    }
  }

  &__title {
    font-size: 23px;
    margin-bottom: 15px;

    @media (max-width: $wrap) {
      font-size: 18px;
    }

    @media (max-width: $xs) {
      font-size: 16px;
    }
  }
}