@import "../base/var";

.banner {
  position: relative;
  width: 100%;
  margin-top: 69px;

  &:before {
    content: "";
    display: block;
    padding-top: 36.7%;

    @media (max-width: $xs) {
      display: none;
    }
  }

  @media (max-width: $xs) {
    margin-top: 52px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 0;

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 60%;
      height: 100%;
      top: 0;
      left: -100px;
      transform: skewX(20deg);
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;

      @media (max-width: 1350px) {
        width: 70%;
      }

      @media (max-width: $md) {
        width: 72%;
      }

      @media (max-width: $sm) {
        width: 90%;
      }

      @media (max-width: $xs) {
        transform: none;
        left: 0;
        width: 100%;
      }
    }

    @media (max-width: $xs) {
      position: relative;
    }
  }

  &__flex {
    height: 100%;
    display: flex;
  }

  &__content {
    z-index: 2;
    position: relative;
    max-width: 625px;
    margin: auto 0;

    @media (max-width: $md) {
      max-width: 525px;
    }

    @media (max-width: $xs) {
      max-width: 100%;
      margin: 45px 0 50px;
    }
  }

  &__title {
    font-size: 36px;
    color: $white;

    @media (max-width: $md) {
      font-size: 30px;
    }

    @media (max-width: $xs) {
      font-size: 24px;
    }
  }

  &__text {
    font-size: 18px;
    color: $white;
    margin-top: 20px;

    @media (max-width: $md) {
      font-size: 16px;
    }

    @media (max-width: $xs) {
      font-size: 14px;
      margin-top: 15px;
    }
  }

  &__button-wrap {
    margin-top: 65px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: $xs) {
      margin-top: 45px;
    }
  }

  &__button {
    border-radius: $radius;
    background: linear-gradient(296.18deg, $red -2.13%, $yellow 120.44%);
    color: $white;
    font-size: 18px;
    padding: 11px 35px;
    border: 0;
    transition: 0.3s;

    &:hover {
      background: linear-gradient(296.18deg, $yellow -2.13%, $red 120.44%);
    }

    @media (max-width: $xs) {
      font-size: 14px;
    }
  }
}