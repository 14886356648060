$font: 'Montserrat', sans-serif;

$r: 400;
$m: 500;
$b: 700;

/* colors */

$black: #000;
$white: #fff;
$grey-text: #878787;
$table-head: #ececec;
$table-stroke: #f8f8f8;
$footer-bg: rgba(229, 229, 229, 0.2);
$yellow: #F7CD47;
$orange: #F37500;
$red: #FF0119;

/* media */

$wrap: 1550px;
$md: 1199px;
$sm: 991px;
$xs: 767px;

$radius: 4px;