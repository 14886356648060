@import "var";

body{
  font-family: $font;
  font-weight: $r;
  line-height: normal;
  overflow-x: hidden;
}

p {
  margin: 0;
}

button, input, textarea, select {
  outline: none;
}

.wrapper {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;

  @media (max-width: $wrap) {
    padding: 0 30px;
  }

  @media (max-width: $xs) {
    padding: 0 15px;
  }
}

a {
  transition: 0.3s;

  &:hover {
    color: $orange;
  }
}

.round-icon {
  box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.1);
  background: $white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 162px;
}

.custom-checkbox {
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 0;
  }

  &__text {
    position: relative;
    padding: 0 0 0 25px;
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 1px;
      left: 0;
      width: 16px;
      height: 16px;
      background: $white;
      border: 4px solid #AAA5A5;
    }
  }

  input:checked + .custom-checkbox__text:before {
    border-color: $yellow;
  }
}

