@import "../base/var";

.metal-form {
  color: $white;
  padding: 32px 24px;
  background: linear-gradient(143.47deg, $yellow -0.98%, $orange 145.11%);
  width: 440px;
  height: 100%;
  flex-shrink: 0;
  border-radius: $radius;
  z-index: 1;

  @media (max-width: $sm) {
    width: 300px;
    max-width: 100%;
  }

  @media (max-width: $xs) {
    padding: 15px;
    margin: auto;
  }

  &__title {
    font-size: 16px;
    font-weight: $b;
    margin-bottom: 30px;

    @media (max-width: $xs) {
      margin-bottom: 15px;
    }
  }

  &__label {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 10px;
    display: block;

    @media (max-width: $xs) {
      font-size: 14px;
    }
  }

  &__number-wrap {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background: $white;
    width: 100%;
    border: 0;
    border-radius: $radius;
    padding: 10px 45px 10px 15px;
    position: relative;
  }

  &__number {
    color: $black;
    width: 100%;
    font-size: 18px;
    border: 0;
  }

  &__number-more {
    position: absolute;
    top: 5px;
    right: 15px;
    width: 0;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #AAA5A5 transparent;
    cursor: pointer;
  }

  &__number-less {
    position: absolute;
    bottom: 5px;
    right: 15px;
    width: 0;
    border-width: 7px;
    border-style: solid;
    border-color: #AAA5A5 transparent transparent transparent;
    cursor: pointer;
  }

  &__checkboxes {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  &__checkbox {
    margin-right: 32px;
  }

  &__phone {
    border: 0;
    background: transparent;
    font-size: 24px;
    font-weight: normal;

    &::placeholder {
      color: $white;
    }
    &::-webkit-input-placeholder {
      color: $white;
    }
    &::-moz-placeholder {
      color: $white;
    }
  }

  &__button {
    display: block;
    width: 100%;
    height: 45px;
    background: $white;
    border-radius: $radius;
    font-size: 18px;
    color: $black;
    border: 0;
    margin: 30px 0 0;
    transition: 0.3s;

    &:hover {
      background: linear-gradient(296.18deg, $red -2.13%, $yellow 120.44%);
      color: $white;
    }
  }
}

.select2-container {
  margin-bottom: 15px;
  background: $white;
  border-radius: $radius;
  height: 44px;

  .select2-selection--single {
    outline: none;
    border: 0;
    font-size: 18px;
    padding: 8px 0 0;

    .select2-selection__rendered {
      padding: 0 15px;
    }

    .select2-selection__arrow {
      top: 8px;
      right: 15px;

      b {
        border-width: 7px;
        border-style: solid;
        border-color: #AAA5A5 transparent transparent transparent;
      }
    }
  }

  .select2-dropdown {
    border: 0;

    .select2-search {
      display: none;
    }

    .select2-results__option {
      padding: 7px 15px;
      cursor: pointer;

      &--highlighted {
        background-color: $yellow;
      }
    }
  }
}